import { useState } from "react";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import RenameSessionDialog from "./RenameSessionDialog";
import RowSpanWithSpaceBetween from "../../common/RowSpanWithSpaceBetween";
import DeleteSessionDialog from "./DeleteSessionDialog";
import { formatEpochTime } from "../../common/EpochTime";

export default function ChatSessionListItem({
  sessionId,
  sessionName,
  sessionUpdatedAt,
  isSelected = false,
  onSessionSelect = () => {},
  onMoreButtonSelect = () => {},
  onSessionRenameSubmit = ({ sessionName }) => {},
  onSessionDeleteSubmit = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const handleMoreButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    onMoreButtonSelect();
  };

  const handleCloseChatSessionMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem
      key={sessionId}
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="more"
          onClick={handleMoreButtonClick}
        >
          <MoreVert />
        </IconButton>
      }
    >
      <ListItemButton
        style={{
          width: "300px",
          backgroundColor: isSelected ? "rgba(0, 0, 0, 0.12)" : "unset",
        }}
        onClick={onSessionSelect}
      >
        <ListItemText
          primary={sessionName}
          primaryTypographyProps={{
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
          secondary={formatEpochTime(sessionUpdatedAt)}
        />
      </ListItemButton>
      {/* Dropdown menu section */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleCloseChatSessionMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Rename Button Entry */}
        <MenuItem onClick={() => setIsOpenRenameDialog(true)}>
          <RowSpanWithSpaceBetween>
            Rename
            <Edit color="primary" />
          </RowSpanWithSpaceBetween>
        </MenuItem>
        {/* Delete Button Entry */}
        <MenuItem onClick={() => setIsOpenDeleteDialog(true)}>
          <RowSpanWithSpaceBetween>
            Delete
            <DeleteForever color="error" />
          </RowSpanWithSpaceBetween>
        </MenuItem>
      </Menu>
      <RenameSessionDialog
        isOpen={isOpenRenameDialog}
        handleClose={(e) => setIsOpenRenameDialog(false)}
        onSubmitRename={onSessionRenameSubmit}
      />
      <DeleteSessionDialog
        isOpen={isOpenDeleteDialog}
        handleClose={(e) => setIsOpenDeleteDialog(false)}
        onSubmitDelete={onSessionDeleteSubmit}
      />
    </ListItem>
  );
}
