import React, { useState } from "react";
import { Button, List } from "@mui/material";

// Local import
import "../index.css";

import { ChatSideBarContainer } from "./style";
import { deleteSession, updateSessionName } from "../../api";
import ChatSessionListItem from "./ChatSessionListItem";
import RowSpanWithSpaceBetween from "../../common/RowSpanWithSpaceBetween";
import { Add } from "@mui/icons-material";
import LoadingSpan from "../../common/LoadingSpan";

const NO_SELECTED_SESSION_VALUE = -1;

const ChatSideBar = ({
  // NOTE: Data structure: {object.sessionId = object}
  chatSessions,
  isLoading = false,
  onSelectSession = ({ sessionId }) => {},
  onCreateSession = () => {},
  onChangeSessionName = ({ sessionId, sessionName }) => {},
  onDeleteSession = ({ sessionId }) => {},
}) => {
  // State management
  const [selectedSessionId, setSelectedSessionId] = useState(
    NO_SELECTED_SESSION_VALUE
  );

  // Transform data

  // Event Handler
  const handleSaveSessionName = async ({ sessionId, sessionName }) => {
    onChangeSessionName({
      sessionId,
      sessionName,
    });
    await updateSessionName({
      sessionId: sessionId,
      sessionName: sessionName,
    });
  };

  const handleSelectSession = (sessionId) => {
    onSelectSession({ sessionId });
    setSelectedSessionId(sessionId);
  };

  const handleCreateSession = () => {
    onCreateSession();
  };
  const handleDeleteSession = async ({ sessionId }) => {
    await deleteSession({ sessionId });
    onDeleteSession({ sessionId });
  };

  return (
    <>
      <ChatSideBarContainer>
        <Button onClick={handleCreateSession} variant="contained">
          <RowSpanWithSpaceBetween spacing={1}>
            <Add />
            New
          </RowSpanWithSpaceBetween>
        </Button>

        <h3>Chat Sessions</h3>
        {isLoading ? (
          <LoadingSpan />
        ) : (
          <List>
            {Object.entries(chatSessions)
              // Sort the entries by date, latest first
              .sort((a, b) => -(a[1].updatedAt - b[1].updatedAt))
              .map(([_, optionValue]) => (
                <ChatSessionListItem
                  key={optionValue.sessionId}
                  sessionId={optionValue.sessionId}
                  sessionName={optionValue.name}
                  sessionUpdatedAt={optionValue.updatedAt}
                  onSessionSelect={() =>
                    handleSelectSession(optionValue.sessionId)
                  }
                  isSelected={selectedSessionId == optionValue.sessionId}
                  onSessionRenameSubmit={({ sessionName }) =>
                    handleSaveSessionName({
                      sessionId: optionValue.sessionId,
                      sessionName,
                    })
                  }
                  onSessionDeleteSubmit={() => {
                    handleDeleteSession({ sessionId: optionValue.sessionId });
                  }}
                />
              ))}
          </List>
        )}
      </ChatSideBarContainer>
    </>
  );
};

export { ChatSideBar, NO_SELECTED_SESSION_VALUE };
