export function getCurrentEpochTime() {
  // Expected epoch format 1696830640.490
  return (Date.now() / 1000).toFixed(3);
}
export function formatEpochTime(epoch) {
  try {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Singapore",
      timeZoneName: "short",
    }).format(new Date(epoch * 1000));
  } catch (error) {
    console.error(error, epoch);
    return getCurrentEpochTime();
  }
}

export function getPreviousTime({
  days = 0,
  months = 0,
  years = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}) {
  const now = new Date();
  now.setFullYear(now.getFullYear() - years);
  now.setMonth(now.getMonth() - months);
  now.setDate(now.getDate() - days);
  now.setHours(now.getHours() - hours);
  now.setMinutes(now.getMinutes() - minutes);
  now.setSeconds(now.getSeconds() - seconds);
  return (now.getTime() / 1000).toFixed(3);
}
