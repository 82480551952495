import { authHeader } from "./authHeader";
import API_ENDPOINT from "../pages/apiConfig";

export function untilSocketAvailable(ws) {
  if (ws.readyState == WebSocket.OPEN) {
    return Promise.resolve(ws);
  }

  return new Promise(function (resolve, reject) {
    ws.onopen = function () {
      resolve(ws);
    };
    ws.onerror = function (err) {
      reject(err);
    };
  });
}

export function authSocket(ws) {
  // Ensure that we setup the
  // callback before sending message
  const promise = new Promise(function (resolve, reject) {
    ws.onmessage = function (event) {
      return resolve(ws);
    };

    ws.onerror = function (err) {
      reject(err);
    };

    ws.onclose = function (event) {
      reject(event.reason);
    };
  });

  ws.send(
    JSON.stringify({
      ...authHeader(),
    })
  );

  return promise;
}

export function createChatSocket({ chatSessionId }) {
  const endpoint = API_ENDPOINT.replace("http://", "").replace("https://", "");
  if (API_ENDPOINT.startsWith("http://")) {
    return new WebSocket(`ws://${endpoint}/ws/${chatSessionId}/open`);
  } else {
    return new WebSocket(`wss://${endpoint}/ws/${chatSessionId}/open`);
  }
}

export async function submitMessage({
  ws,
  content,
  filterValue = {},
  documentUrl = "",
}) {
  // nullify documentUrl if it's empty
  if (documentUrl === "") {
    documentUrl = null;
  }

  ws.send(
    JSON.stringify({
      content,
      filterValue,
      documentUrl,
    })
  );
}

export class ChatSocket {
  constructor({ chatSessionId }) {
    this.ws = createChatSocket({ chatSessionId });
    this.receiveCallbacks = [];
  }

  async setup() {
    await untilSocketAvailable(this.ws);
    this.ws.onmessage = (event) => this.route(event);

    await authSocket(this.ws);

    // Add callbacks
  }

  addReceiveCallback(callback) {
    this.receiveCallbacks.push(callback);
  }

  route(event) {
    try {
      const { eventType, content } = JSON.parse(event.data);
      for (let callback in this.receiveCallbacks) {
        callback({ eventType, content });
      }
    } catch (error) {
      console.error(error);
      this.release();
    }
  }

  release() {
    try {
      this.ws.close();
    } catch (error) {}
  }
}

// const CHAT_SOCKET_CACHE = {
//   // sessionId: object
//   // Only allow one socket at a time.
// };

// export function getSingleTonChatSocket({ chatSessionId }) {
//   let chatSocket = CHAT_SOCKET_CACHE[chatSessionId];
//   if (chatSocket === null) {
//     // release all sockets
//     for (var key in CHAT_SOCKET_CACHE) {
//       var result = CHAT_SOCKET_CACHE[key];
//       result.release();
//       delete CHAT_SOCKET_CACHE[key];
//     }

//     // Create new chat socket
//     chatSocket = new ChatSocket({ chatSessionId });
//   }

//   return chatSocket;
// }
