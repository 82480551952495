import API_ENDPOINT from "../pages/apiConfig";
import { authHeader } from "./authHeader";

export async function fetchSearchAndGenerate({ userInput, filterValue }) {
  const question = `${userInput}`;

  const response = await fetch(`${API_ENDPOINT}/query/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      // userId,
      question,
      filterValue,
    }),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }

  const data = (await response.json()).data;

  return {
    response: data.response,
    sources: data.sources.map((source) => source.original_url),
  };
}
