import { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { MenuItem, Stack } from "@mui/material";

// Local import
import {
  DEFAULT_DOCUMENT_URL,
  FILTER_OPTIONS,
  getFilterDefaultState,
} from "../../../settings/SearchFilterConst";
import { ChatFilterContext } from "../ChatFilterContext";
import { submitValidateUrl } from "../../../api";
import { CheckExistSnackbar } from "./CheckExistSnackbar";

export function ChatFilterDialog({ isOpen, handleClose = () => {} }) {
  const { filterValue, updateFilterValue } = useContext(ChatFilterContext);

  const [responsiveFilter, setResponsiveFilter] = useState({ ...filterValue });
  const [isDataFilterDisabled, setIsDataFilterDisabled] = useState(false);

  const [isCheckExistSnackbarOpen, setIsCheckExistSnackbarOpen] =
    useState(false);

  const [checkExistSnackbarState, setCheckExistSnackbarState] = useState("");

  // Hanlder
  const handleFilterChange = (key, value) => {
    // Once document url is set, all other filter will be disabled
    if (key == "DocumentURL") {
      if (value === DEFAULT_DOCUMENT_URL) {
        setIsDataFilterDisabled(false);
        setResponsiveFilter({
          ...getFilterDefaultState(),
        });
        return;
      } else {
        setIsDataFilterDisabled(true);
        setResponsiveFilter({
          ...getFilterDefaultState(),
          DocumentURL: value,
        });
        return;
      }
    }

    // Normal case of filter change
    setResponsiveFilter({
      ...responsiveFilter,
      [key]: value,
    });
  };

  const handleResetFilter = () => {
    setResponsiveFilter({ ...getFilterDefaultState() });
    setIsDataFilterDisabled(false);
  };

  const handleSave = async () => {
    // Validate filter document url
    if (responsiveFilter.DocumentURL !== DEFAULT_DOCUMENT_URL) {
      await handleCheckExist();
    }

    updateFilterValue({ ...responsiveFilter });
    handleClose();
  };

  const handleCloseCheckExistSnackbar = () => {
    setIsCheckExistSnackbarOpen(false);
  };

  const handleCheckExist = async () => {
    if (String(responsiveFilter.DocumentURL).includes("AllItems.aspx")) {
      setIsCheckExistSnackbarOpen(true);
      setCheckExistSnackbarState("WRONG_FORMAT");
      return;
    }

    try {
      const data = await submitValidateUrl({
        url: responsiveFilter.DocumentURL,
      });
      const cleanedUrl = data.url;

      if (data.union_permission.length === 0) {
        setIsCheckExistSnackbarOpen(true);
        setCheckExistSnackbarState("PERMISSION_DENIED");
        return;
      }

      setIsCheckExistSnackbarOpen(true);
      setCheckExistSnackbarState("DOCUMENT_FOUND");
      handleFilterChange("DocumentURL", cleanedUrl);
    } catch (error) {
      // document not found
      setIsCheckExistSnackbarOpen(true);
      setCheckExistSnackbarState("DOCUMENT_NOT_FOUND");
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={"sm"}>
        {/* Filter for document    */}
        <DialogTitle>Filter for document</DialogTitle>

        <DialogContent>
          {/*  */}
          <Stack
            direction={"column"}
            spacing={4}
            style={{
              margin: "auto",
              width: "95%",
            }}
          >
            {/* Select document by URL */}
            <DialogContentText>Select document by URL</DialogContentText>
            <TextField
              label="Document URL"
              value={responsiveFilter.DocumentURL}
              onChange={(e) =>
                handleFilterChange("DocumentURL", e.target.value)
              }
              fullWidth
            />
            {/* Check exist button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckExist}
            >
              Check exist
            </Button>
            <CheckExistSnackbar
              isOpen={isCheckExistSnackbarOpen}
              handleClose={handleCloseCheckExistSnackbar}
              state={checkExistSnackbarState}
            />
            <DialogContentText>
              Filter can help the improve the precision of your conversation.
            </DialogContentText>
            {/* Document Type Filter */}
            <TextField
              select
              label="Filter: Document Type"
              value={responsiveFilter.DocumentType}
              onChange={(e) =>
                handleFilterChange("DocumentType", e.target.value)
              }
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.DocumentType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Project Name Filter */}
            <TextField
              select
              label="Filter: Project name"
              value={responsiveFilter.ProjectName}
              onChange={(e) =>
                handleFilterChange("ProjectName", e.target.value)
              }
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.ProjectName.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Discipline Filter */}
            <TextField
              select
              label="Filter: Discipline"
              value={responsiveFilter.Discipline}
              onChange={(e) => handleFilterChange("Discipline", e.target.value)}
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.Discipline.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Project Phase Filter */}
            <TextField
              select
              label="Filter: Project Phase"
              value={responsiveFilter.ProjectPhase}
              onChange={(e) =>
                handleFilterChange("ProjectPhase", e.target.value)
              }
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.ProjectPhase.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Ownership Filter */}
            <TextField
              select
              label="Filter: Ownership"
              value={responsiveFilter.Ownership}
              onChange={(e) => handleFilterChange("Ownership", e.target.value)}
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.Ownership.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Version Control Filter */}
            <TextField
              select
              label="Filter: Version Control"
              value={responsiveFilter.VersionControl}
              onChange={(e) =>
                handleFilterChange("VersionControl", e.target.value)
              }
              fullWidth
              disabled={isDataFilterDisabled}
            >
              {FILTER_OPTIONS.VersionControl.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
            {/* Tags Filter */}
            <TextField
              label="Filter: Tags"
              value={responsiveFilter.Tags}
              onChange={(e) => handleFilterChange("Tags", e.target.value)}
              fullWidth
              disabled={isDataFilterDisabled}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleResetFilter}>
            Reset
          </Button>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
