import { Alert, Snackbar } from "@mui/material";

export const CheckExistSnackbar = ({ isOpen, handleClose, state }) => {
  let alertComponent = null;

  if (state === "WRONG_FORMAT") {
    alertComponent = (
      <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
        You use the wrong format of URL. Please go to SharePoint Folder UI and
        get the copy the link.
      </Alert>
    );
  }

  if (state === "PERMISSION_DENIED") {
    alertComponent = (
      <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
        You don't have permission to access this document
      </Alert>
    );
  }

  if (state === "DOCUMENT_NOT_FOUND") {
    alertComponent = (
      <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
        Document not found
      </Alert>
    );
  }

  if (state === "DOCUMENT_FOUND") {
    alertComponent = (
      <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
        Requested document found!
      </Alert>
    );
  }

  if (!!!alertComponent) {
    return <></>;
  }

  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      {alertComponent}
    </Snackbar>
  );
};
