import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// Local import
import "../index.css";
import AlertModal from "../ModalPopupAlert";
import { SearchContainer, MainContentContainer } from "./searchStyle";
import { SearchSideBar } from "./SearchSideBar";
import { MarkdownTextField } from "../../common/Markdown";
import { fetchSearchAndGenerate } from "../../api/search";
import LoadingSpan from "../../common/LoadingSpan";

const Search = () => {
  const [currentFilter, setCurrentFilter] = useState({});
  const [userInput, setUserInput] = useState("");
  const [searchResponse, setSearchResponse] = useState("");
  const [searchSource, setSearchSource] = useState("");

  const [loading, setLoading] = useState(false);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState("");
  const [expanded, setExpanded] = useState(false);

  // Handler
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent the default Enter key behavior (e.g., adding a new line)
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      setSearchResponse("");
      setExpanded(false);

      const data = await fetchSearchAndGenerate({
        userInput: userInput,
        filterValue: currentFilter,
      });
      setSearchResponse(data.response);
      setSearchSource(data.sources);
    } catch (error) {
      setMessageDisplay(`Error in Search\n${error.message}`);
      setOpenAlertModal(true);
      setSearchResponse("Error fetching GPT response");
      setSearchSource("No references available");
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeFilter = (newFilterValue) => {
    setCurrentFilter(newFilterValue);
  };

  return (
    <SearchContainer>
      <SearchSideBar onChangeFilter={handleOnChangeFilter} />
      <MainContentContainer>
        <Container
          maxWidth="100vw"
          style={{
            marginTop: "20px",
            textAlign: "left",
            marginLeft: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="h5">Search in Knowledge Base</Typography>
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <TextField
              id="searchInput"
              label="Search your knowledge base"
              variant="outlined"
              fullWidth
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />

            <Button
              variant="contained"
              onClick={handleSearch}
              style={{ marginLeft: "10px" }}
            >
              Search
            </Button>
          </Box>
          <Container
            style={{
              width: "100%",
              // height: "100%",
            }}
          >
            {loading ? (
              <div
                style={{
                  height: 500,
                }}
              >
                <LoadingSpan />
              </div>
            ) : (
              <MarkdownTextField value={searchResponse} />
            )}
          </Container>
        </Container>
      </MainContentContainer>
      <AlertModal
        open={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        message={messageDisplay}
      />
    </SearchContainer>
  );
};

export default Search;
