import { Stack } from "@mui/material";

// Document: A div that span the whole parent width
// All children will be place with spacing in between
export default function RowSpanWithSpaceBetween({
  spacing = 4,
  fullHeight = false,
  children,
}) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={spacing}
        style={{ width: "100%", height: fullHeight ? "100%" : "unset" }}
        useFlexGap={true}
      >
        {children}
      </Stack>
    </>
  );
}
