import { MsalAuthProvider, LoginType } from "react-aad-msal";

const TENANT_ID = "8d348135-f7aa-4a4c-a71c-31df44c7cac3";
const CLIENT_ID = "90194a33-3475-4284-86c8-870426634fbc";

const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    clientId: CLIENT_ID,
    tenantId: TENANT_ID,
    redirectUri:
      process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000/login",
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read"],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
