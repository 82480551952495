import API_ENDPOINT from "../pages/apiConfig";
import { authHeader } from "./authHeader";

export async function fetchFilesystemStatus() {
  const response = await fetch(`${API_ENDPOINT}/status/filesystem`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return [...data];
}
