import React from "react";

const Home = ({ userName, userRole }) => {
  // Check if userRole is defined before using map
  //const modifiedRoles=userRole;
  //const newmodifiedRoles = Array.isArray(modifiedRoles) ? modifiedRoles.map(role => role.replace('Role.', '')) : [];
  console.log(userRole);

  return (
    <>
      <h1
        style={{
          paddingLeft: 16,
        }}
      >
        Welcome {userName} to Intelligent Knowledge Management Application!
      </h1>
    </>
  );
};

export default Home;
