import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

// Local import
import "../index.css";

const ChatContainer = styled("div")({
  display: "flex",
  height: "100%",
  // backgroundColor: "black", // Set your desired background color
  // height: "88vh",
  // height: "100%",
  // marginTop: "-20px",
  // marginLeft: "-12px",
  // marginBottom: "-100px",
  // overflow: "hidden", // Prevent scroll bar
  // overflowY: "auto",
});

const ChatSideBarContainer = styled("div")({
  flex: 1, // Adjusted the flex property
  // overflowY: "auto",
  paddingTop: "8px",
  paddingLeft: "16px",
  height: "112%",
  borderRight: "1px dashed #ccc",
  backgroundColor: "#f9f9f9",
  overflow: "scroll",
});
const HeadingContainer = styled("h2")({
  fontWeight: "bold",
  backgroundColor: "white", // Set your desired background color
  position: "fixed",
  flex: 1,
  padding: "4px",
  zindex: 2,
});

const ChatContentContainer = styled("div")({
  flex: 4, // Adjusted the flex property
  // overflowY: "scroll",
  marginLeft: "16px",
  marginRight: "16px",
  // padding: "4px",
  // height: "100%",
  // position: "relative",
  // backgroundColor: "red", // FIXME: debug
});

const MessageContainer = styled("div")({
  overflowY: "auto",
  flex: 1,
  padding: "4px",
  marginTop: "60px",
  // maxHeight: "68vh",
  width: "inherit",
  zIndex: 1,
  // backgroundColor: "yellow",
});

const Message = styled("div")({
  marginBottom: "8px",
});

const AvatarContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
  maxWidth: "90vw",
});

const SenderContainer = styled("div")({
  marginLeft: "8px",
  fontWeight: "bold",
});

const InputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  // padding: "8px",
  // backgroundColor: "green", // Set your desired background color
  // marginBottom: "20px",
  width: "100%",
  // position: "relative",
  // bottom: "30px",
  // position: "absolute",
  // bottom: 0,
  zIndex: 2,
  position: "absolute",
  bottom: 0,
  right: 0,
  left: 0,
  // backgroundColor: "green",
});

const InputField = styled(TextField)({
  flex: 1,
  marginRight: "16px",
  borderRadius: "16px",
  width: "1",
  backgroundColor: "white", // Set your desired background color
  zIndex: 2,
});

const FeatureButtons = styled(Button)({
  marginRight: "2px",
  marginBottom: "12px",
  marginTop: "12px",
});

const Emoji = styled("span")({
  fontSize: "1.5em", // Adjust the font size as needed
});

export {
  ChatContainer,
  ChatSideBarContainer,
  ChatContentContainer,
  HeadingContainer,
  MessageContainer,
  Message,
  AvatarContainer,
  SenderContainer,
  InputContainer,
  InputField,
  FeatureButtons,
  Emoji,
};
