import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { fetchFilesystemStatus } from "../../api/status";
import { getCurrentEpochTime } from "../../common/EpochTime";
import LoadingSpan from "../../common/LoadingSpan";
import { saveAs } from "file-saver";

function makeOnlineViewableUrl(url) {
  if (String(url).includes(".csv")) {
    return url;
  }
  const urlObj = new URL(url);
  urlObj.searchParams.set("csf", "1");
  urlObj.searchParams.set("web", "1");
  return urlObj.toString();
}

function urlToDisplayValue(url, isStrip = true) {
  if (String(url).includes(".csv")) {
    return url;
  }
  try {
    let newUrl = decodeURIComponent(url);
    if (isStrip) {
      newUrl = newUrl.replace(
        "https://seatrium.sharepoint.com/sites/IKM-Data/Shared Documents/General/",
        ""
      );
      if (newUrl.startsWith("https")) {
        return newUrl;
      }
      let splits = newUrl.split("/");
      if (splits.length > 3) {
        splits = [splits[0], splits[1], "...", splits.at(-1)];
        newUrl = splits.join("/");
      }

      newUrl = ".../" + newUrl;
    }
    return newUrl;
  } catch (error) {
    console.error(error);
    return url;
  }
}

const COLUMNS_DEFINITION = [
  // { field: "id", headerName: "ID", width: 70 },
  {
    field: "url",
    headerName: "URL",
    width: 1000,
    renderCell: ({ ...props }) => {
      return (
        <a
          href={makeOnlineViewableUrl(props.value)}
          target="_blank"
          rel="noreferrer"
        >
          {urlToDisplayValue(props.value)}
        </a>
      );
    },
  },
  { field: "status", headerName: "Status", width: 150 },
  // { field: "urlHash", headerName: "URL Hash", width: 250 },
  // { field: "filehash", headerName: "File Hash", width: 250 },
  // { field: "permissionIds", headerName: "Permission IDs", width: 300 },
  // { field: "filtersStr", headerName: "Filters", width: 300 },
  // { field: "createdAt", headerName: "Created At", width: 150 },
  { field: "updatedAt", headerName: "(SP) Updated At", width: 150 },
  { field: "statusUpdatedAt", headerName: "Indexed at", width: 150 },
];

function formatTime(epoch) {
  try {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Singapore",
      // timeZoneName: "short",
    }).format(new Date(epoch * 1000));
  } catch (error) {
    console.error(error, epoch);
    return getCurrentEpochTime();
  }
}

const FIVE_MINUTE_INTERVAL = 1000 * 60 * 5;
export function StatusPage() {
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [dynamicInterval, setDynamicInterval] = useState(TEN_MINUTE_INTERVAL);
  useEffect(() => {
    const fetchData = async () => {
      let data = await fetchFilesystemStatus();
      data = data.map((data) => {
        return {
          ...data,
          id: data.url,
          createdAt: formatTime(data.createdAt),
          updatedAt: formatTime(data.updatedAt),
          statusUpdatedAt: formatTime(data.statusUpdatedAt),
        };
      });

      setStatusData(data);
    };

    // First time run immediate
    setLoading(true);
    fetchData().catch(console.error);
    setLoading(false);

    // Then schedule a 1 min polling
    // const ONE_MINUTE_INTERVAL = 1000 * 5;
    const intervalId = setInterval(() => {
      setLoading(true);
      fetchData().catch(console.error);
      setLoading(false);
    }, FIVE_MINUTE_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (loading || statusData.length === 0) {
    return <LoadingSpan />;
  }

  const handleReload = async (e) => {
    let data = await fetchFilesystemStatus();
    data = data.map((data) => {
      return {
        id: data.url,
        ...data,
      };
    });

    setStatusData(data);
  };

  const handleDownload = () => {
    var blob = new Blob([JSON.stringify(statusData, null, 2)], {
      type: "application/json;charset=utf-8",
    });
    const currentTime = getCurrentEpochTime();
    saveAs(blob, `file-status-log-${currentTime}.json`);
  };

  return (
    <div style={{ width: "95%", margin: "auto", marginTop: 16 }}>
      <div style={{ marginBottom: 16 }}>
        <h2>Filesystem Status</h2>
        <Stack direction={"row"} spacing={2}>
          <Button onClick={handleReload} variant="contained">
            Reload
          </Button>

          <Button onClick={handleDownload} variant="contained">
            Download JSON Log
          </Button>
        </Stack>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={statusData}
          columns={COLUMNS_DEFINITION}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20]}
          checkboxSelection
        />
      </div>
    </div>
  );
}
