import Navbar from "./Navbar.js";
// import API_ENDPOINT from "./pages/apiConfig.js";
import React, { useEffect, useState } from "react";
import { authProvider } from "./authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import Session from "react-session-api";
import AlertModal from "./pages/ModalPopupAlert.js";
import { upsertRegisterUser } from "./api/index.js";
import LoadingSpan from "./common/LoadingSpan.jsx";
import VersionContext from "./VersionContext.js";
import versionFile from "./version.txt";
import { ROLES_MAPPING } from "./settings/RolesMappingConst";

Session.config({
  browserStorage: true, // use browser sessionStorage
});

function UserLogin({ accountInfo, logout }) {
  const [doneRegister, setDoneRegister] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState("");
  const [version, setVersion] = useState("");
  const [userRoles, setRoles] = useState(["Role.User"]);

  useEffect(() => {
    const emailAddress = String(accountInfo.account.userName);
    const userId = String(accountInfo.account.accountIdentifier);
    const userName = String(accountInfo.account.name);

    const fetchVersionString = async () => {
      const res = await fetch(versionFile);
      const versionStr = await res.text();
      setVersion(versionStr);
    };

    const submitUserData = async () => {
      await upsertRegisterUser({
        userId: userId,
        emailAddress: emailAddress,
        userName: userName,
        jwtAccessToken: accountInfo.jwtIdToken,
      });

      // Add JWT token to session
      Session.set("accountInfo", JSON.stringify(accountInfo));

      // Check static roles
      if (ROLES_MAPPING.admin.includes(emailAddress.toLowerCase())) {
        setRoles([...userRoles, "Role.Admin"]);
      }

      // Alow application render
      setDoneRegister(true);
    };

    // Fetch version string for app info.
    fetchVersionString().catch(console.error);

    submitUserData().catch((error) => {
      setMessageDisplay(`Error making API call\n${error.message}`);
      setOpenAlertModal(true);
    });
  }, [accountInfo]);

  if (!doneRegister) {
    return (
      <>
        <LoadingSpan />
      </>
    );
  }

  return (
    <VersionContext.Provider value={version}>
      <Navbar
        email={accountInfo.account.userName}
        userName={accountInfo.account.name}
        userRole={userRoles}
        logout={logout}
      />
      <AlertModal
        open={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        message={messageDisplay}
      />
    </VersionContext.Provider>
  );
}

function Apps() {
  return (
    <>
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return <UserLogin accountInfo={accountInfo} logout={logout} />;
            case AuthenticationState.Unauthenticated:
              return (
                <div>
                  {error && (
                    <p>
                      <span>
                        An error occurred during authentication, please try
                        again!
                      </span>
                    </p>
                  )}
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
            case AuthenticationState.InProgress:
              return <LoadingSpan />;

            default:
          }
        }}
      </AzureAD>
    </>
  );
}
export default Apps;
