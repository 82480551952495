import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export default function DeleteSessionDialog({
  isOpen,
  handleClose = () => {},
  onSubmitDelete = () => {},
}) {
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Session Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you sure to delete this chat session? This action is permanent
            and can not be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="error" onClick={onSubmitDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
