import React from "react";

const AlertModal = ({ open, onClose, message }) => {
  const handleClose = () => {
    onClose();
  };

  if (!open) return null;

  return (
    <div onClick={handleClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="modalRight">
          <div className="content">
            <p className="textfield">
              {message.split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  <span style={{ fontSize: i === 0 ? "larger" : "inherit" }}>
                    {line}
                  </span>

                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
          <div className="btnContainer">
            <button className="btnPrimary" onClick={handleClose}>
              <span className="bold">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
