import { useState } from "react";
import ChatLogic from "./logic";
import { getFilterDefaultState } from "../../settings/SearchFilterConst";
import { ChatFilterContext } from "./ChatFilterContext";

const Chat = () => {
  const [filterValue, updateFilterValue] = useState({
    ...getFilterDefaultState()
  })

  return <>
    <ChatFilterContext.Provider value={{ filterValue, updateFilterValue }}>
      <ChatLogic />
    </ChatFilterContext.Provider>
  </>

};


export default Chat;