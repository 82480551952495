import {
  ContentCopy,
  ThumbUpOutlined,
  ThumbDownOutlined,
  ThumbUp,
  ThumbDown,
} from "@mui/icons-material";
import { Button, styled, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { MarkdownTextField } from "../../common/Markdown";
import { ImageModel } from "../../common/ImageModal";

const MessageBubbleStyle = styled("div")(({ isUser }) => ({
  position: "relative",
  padding: "8px 32px",
  borderRadius: "24px",
  marginTop: "16px",
  backgroundColor: isUser ? "#F4F4F4" : "#F9F9F9",
  alignSelf: isUser ? "flex-end" : "flex-start",
  textAlign: isUser ? "right" : "left",
  //   width: isUser ? "fit-content" : "70%",
  width: "fit-content",
  maxWidth: isUser ? "50%" : "70%",
  minHeight: "fit-content",
  marginLeft: isUser ? 0 : 8,
  marginRight: !isUser ? 0 : 8,
}));

const ImageView = ({ imageUrls }) => {
  if (imageUrls == null) return <></>;
  if (imageUrls.length === 0) return <></>;

  const uniqueImageUrls = [...new Set(imageUrls)];

  return (
    <div>
      <h2>Images Found</h2>
      {uniqueImageUrls.map((url) => {
        return <ImageModel key={url} imageUrl={url} />;
      })}
    </div>
  );
};

export const MessageBubble = ({
  isUser,
  content,
  scrollRef,
  imageUrls = [],
  children,
  feedback,
  isContent = true,
  isShowFeedBack = true,
  isShowCopyBtn = true,
  onFeedback = async ({ feedback }) => {},
}) => {
  const [vote, setVote] = useState(feedback);
  // Bot answer
  const contentRef = useRef(null);
  if (isUser)
    return (
      <>
        <MessageBubbleStyle
          isUser={isUser}
          style={{ backgroundColor: "#f9f9f9" }}
        >
          {content}
        </MessageBubbleStyle>
      </>
    );

  const handleCopy = () => {
    if (contentRef !== null) {
      const data = contentRef.current.textContent;
      navigator.clipboard.writeText(data);
    }
  };

  const handleFeedbackDown = (e) => {
    onFeedback({ feedback: "DOWN" });
    setVote("DOWN");
  };

  const handleFeedBackUp = (e) => {
    onFeedback({ feedback: "UP" });
    setVote("UP");
  };

  return (
    <>
      <MessageBubbleStyle ref={scrollRef} isUser={isUser}>
        {isShowCopyBtn ? (
          <Button
            style={{
              position: "absolute",
              right: 0,
              top: 16,
            }}
            onClick={handleCopy}
          >
            <ContentCopy color="primary" fontSize="small" />
          </Button>
        ) : (
          <></>
        )}

        {!isContent ? (
          <>{children}</>
        ) : (
          <div
            style={{
              maxWidth: "97%",
            }}
            ref={contentRef}
          >
            {MarkdownTextField({ value: content })}
            <ImageView imageUrls={imageUrls} />

            {/* Feedback section */}
            {isShowFeedBack ? (
              <div
                style={{
                  position: "absolute",
                  right: 8,
                  bottom: 0,
                }}
              >
                <IconButton
                  onClick={handleFeedBackUp}
                  aria-label="fingerprint"
                  color="primary"
                >
                  {vote === "UP" ? <ThumbUp /> : <ThumbUpOutlined />}
                </IconButton>
                <IconButton
                  onClick={handleFeedbackDown}
                  aria-label="fingerprint"
                  color="primary"
                >
                  {vote === "DOWN" ? <ThumbDown /> : <ThumbDownOutlined />}
                </IconButton>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </MessageBubbleStyle>
    </>
  );
};
