// Error403.js
import React from "react";

const Error403 = () => {
  return (
    <div>
      <h1>403 Forbidden</h1>
      <p>Sorry, you don't have permission to access this page.</p>
      <p>Please select any button to go back.</p>
      {/* You can add more content or styling as needed */}
    </div>
  );
};

export default Error403;
