import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { dark, a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";

export function cleanMarkdownMessage(content) {
  const pattern = new RegExp(/(```)(\w*)([^`]+)(```)/gm);
  return String(content).replaceAll(
    pattern,
    (match, group1, group2, group3, group4, offset, string, groups) => {
      if (group2 === "markdown") {
        return group3;
      }
      return `~~~${group2}\n${group3}~~~`;
    }
  );
}

export function removeFigure(content) {
  const pattern = new RegExp(/<figure>\n\n(^<img .* \/>$)\n\n<\/figure>/gm);
  return String(content).replaceAll(
    pattern,
    (match, group1, offset, string, groups) => {
      return "";
    }
  );
}

export const MarkdownTextField = ({ value }) => {
  value = cleanMarkdownMessage(value);
  value = removeFigure(value);
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      children={value}
      components={{
        a(props) {
          const { node, ...rest } = props;
          return <a {...rest} target="_blank" rel="noreferrer" />;
        },
        h4(props) {
          const { node, ...rest } = props;
          return <h1 {...rest} style={{ color: "#1976d2" }} />;
        },
        h2(props) {
          const { node, ...rest } = props;
          return <h2 {...rest} style={{ color: "#1976d2" }} />;
        },
        h3(props) {
          const { node, ...rest } = props;
          return <h3 {...rest} style={{ color: "#1976d2" }} />;
        },
        h4(props) {
          const { node, ...rest } = props;
          return <h4 {...rest} style={{ color: "#1976d2" }} />;
        },
      }}
      // components={componentTransform}
    />
    // <Markdown
    //   children={value}
    //   components={{
    //     code(props) {
    //       const { children, className, node, ...rest } = props;
    //       const match = /language-(\w+)/.exec(className || "");
    //       return match ? (
    //         <SyntaxHighlighter
    //           {...rest}
    //           PreTag="div"
    //           children={String(children).replace(/\n$/, "")}
    //           language={match[1]}
    //           style={a11yDark}
    //         />
    //       ) : (
    //         <code {...rest} className={className}>
    //           {children}
    //         </code>
    //       );
    //     },
    //   }}
    // />
  );
};
