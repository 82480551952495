export const ROLES_MAPPING = {
  // roles -> email
  admin: [
    "hung.nguyen@rackspace.com",
    "chris.chauhan@rackspace.com",
    "bernad.peter@seatrium.com",
    "saurabh.shinde@seatrium.com",
    "shivam.bahuguna@seatrium.com",
  ],
};
