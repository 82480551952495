import { CircularProgress, Container, Stack } from "@mui/material";

// Document: A div that span the whole parent width
// All children will be place with spacing in between
export default function LoadingSpan() {
  return (
    <>
      <Container
        style={{
          height: "100%",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Container>
    </>
  );
}
