import API_ENDPOINT from "../pages/apiConfig";
import { authHeader } from "./authHeader";

export async function upsertRegisterUser({
  userId,
  emailAddress,
  userName,
  jwtAccessToken,
}) {
  const response = await fetch(`${API_ENDPOINT}/user/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtAccessToken}`,
    },
    body: JSON.stringify({
      userId,
      emailAddress,
      userName,
    }),
  });
  return response.json();
}

export async function fetchAllChatSession() {
  const response = await fetch(`${API_ENDPOINT}/chat/sessions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return [...data];
}

export async function fetchMessage(chatSessionId) {
  const response = await fetch(
    `${API_ENDPOINT}/chat/session/${chatSessionId}/messages`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return [...data];
}

export async function generateSessionId() {
  const response = await fetch(`${API_ENDPOINT}/chat/sessionId`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const sessionId = (await response.json()).data;
  return sessionId;
}

export async function updateSessionName({ sessionId, sessionName }) {
  const response = await fetch(`${API_ENDPOINT}/chat/sessionName`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      sessionId,
      sessionName,
    }),
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return { ...data };
}
export async function deleteSession({ sessionId }) {
  const response = await fetch(`${API_ENDPOINT}/chat/${sessionId}`, {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return { ...data };
}

export async function feedBackMessage({ sessionId, messageId, feedback }) {
  const response = await fetch(`${API_ENDPOINT}/chat/feedback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      sessionId,
      messageId,
      feedback,
    }),
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return { ...data };
}

const cleanUrlVariant1 = (url) => {
  const baseSharePointUrl = "https://seatrium.sharepoint.com";
  // Remove query and fragment
  url = url.split("#")[0].split("?")[0];
  // find index of "sites/IKM-Data"
  const siteIndex = url.indexOf("sites/IKM-Data");
  if (siteIndex !== -1) {
    url = url.substring(siteIndex);
  }

  return `${baseSharePointUrl}/${url}`;
};

const cleanUrlVariant2 = (url) => {
  const baseSharePointUrl = "https://seatrium.sharepoint.com";
  // Remove query and fragment
  url = url.split("#")[0].split("?")[0];
  // find index of "sites/IKM-Data"
  const siteIndex = url.indexOf("sites/IKM-Data");
  if (siteIndex !== -1) {
    url = url.substring(siteIndex);
  }

  // FIXME: need a correct way to decode a "halfway-decoded URL"
  url = url.replace("(", "%28").replace(")", "%29");

  return `${baseSharePointUrl}/${url}`;
};

export async function checkDocumentExist({ url }) {
  const response = await fetch(`${API_ENDPOINT}/chat/validateUrl`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({ url }),
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = (await response.json()).data;
  return { ...data };
}

/**
 * Checks if a document exists at the given SharePoint URL and validates user permissions
 * @param {Object} params - The parameters object
 * @param {string} params.url - The SharePoint document URL to validate
 * @returns {Promise<{url: string}>} Response containing the validated SharePoint URL
 * @throws {Error} If the request fails or document is not found
 */

export async function submitValidateUrl({ url }) {
  let data = null;
  try {
    data = await checkDocumentExist({ url: cleanUrlVariant1(url) });
  } catch (error) {
    try {
      data = await checkDocumentExist({ url: cleanUrlVariant2(url) });
    } catch (error) {
      data = await checkDocumentExist({ url: url });
    }
  }

  return {
    ...data,
    url: data.url,
  };
}

export async function fetchPageDiff({
  sourceDocUrl,
  targetDocUrl,
  sourceStartPage = 1,
  sourceEndPage = -1,
  targetStartPage = 1,
  targetEndPage = -1,
}) {
  const response = await fetch(`${API_ENDPOINT}/compare/pageMap`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      sourceDoc: sourceDocUrl,
      sourceStartPage: sourceStartPage,
      sourceEndPage: sourceEndPage,
      targetStartPage: targetStartPage,
      targetEndPage: targetEndPage,
      targetDoc: targetDocUrl,
    }),
  });
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  const data = await response.json();
  return {
    pageDiffs: data.data.pageDiffs,
    overviews: data.data.overviews,
  };
}
