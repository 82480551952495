import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import {
  Search as SearchIcon,
  FileUpload as FileUploadIcon,
  Chat as ChatIcon,
  Storage as StorageIcon,
  Explore as ExploreIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  HelpOutline as HelpOutlineIcon,
  AccountTree as AccountTreeIcon,
} from "@mui/icons-material";
import Home from "./pages/Home.js";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Search from "./pages/Search";
import Compare from "./pages/Compare";
import Chat from "./pages/Chat";
import Error403 from "./pages/Error403";
import NotFound from "./pages/NotFound";
import { StatusPage } from "./pages/Status/index.jsx";
import logo from "./images/seatrium-logo.svg";
import RowSpanWithSpaceBetween from "./common/RowSpanWithSpaceBetween.jsx";
import VersionContext from "./VersionContext.js";

const ICON_MAPPING = {
  Home: <HomeIcon />,
  Search: <SearchIcon />,
  Chat: <ChatIcon />,
  Compare: <ChatIcon />,
  Status: <StorageIcon />,
  // "Add Document": <FileUploadIcon />,
  // "Prompt Exploration": <ExploreIcon />,
  Logout: <LogoutIcon />,
  Help: <HelpOutlineIcon />,
  Provenance: <AccountTreeIcon />,
};

const drawerWidth = 280;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const NavigationButtons = ({
  allowedTabs,
  navigateTo,
  logout,
  userRoles,
  email,
  userName,
}) => {
  const versionContent = React.useContext(VersionContext);
  const handleButtonClick = (text) => {
    if (text === "Logout") {
      logout();
    } else if (text === "Provenance") {
      // Navigate to the Provenance page
      navigateTo("provenance"); // Change 'provenance' to the actual path for the Provenance page
    } else {
      navigateTo(text.toLowerCase().replace(/\s/g, "-"));
    }
  };

  const renderButton = (text, icon, tooltipContent, hasText = true) => {
    return (
      <Tooltip key={text} title={tooltipContent} arrow>
        <Button
          id={text}
          key={text}
          onClick={() => handleButtonClick(text)}
          style={{
            display: "flex",
            alignItems: "left",
            minWidth: 0,
            backgroundColor: "inherit",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
            userSelect: "none",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#007bff";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "inherit";
          }}
          startIcon={icon}
        >
          {hasText && text}
        </Button>
      </Tooltip>
    );
  };

  // const modifiedRoles = userRoles[0];
  // const newModifiedRoles = Array.isArray(modifiedRoles)
  //   ? modifiedRoles.map((role) => role.replace("Role.", ""))
  //   : [];
  const tooltipContent = `User: ${userName}\nEmail: ${email}\n Version: ${versionContent}`;

  return (
    <>
      {" "}
      {/*Keeping it blank to align the navigation to parent container*/}
      {allowedTabs.map((text) => {
        if (text === "Home" || text === "Logout" || text === "Provenance") {
          // Render icons with tooltips for 'Home','Provenance', and 'Logout'
          return renderButton(text, ICON_MAPPING[text], text, false);
        } else if (text === "Help") {
          // Render question mark icon with tooltip for 'Help'
          return (
            <Tooltip
              key="t1"
              title={tooltipContent.split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
              arrow
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px 0",
                  backgroundColor: "inherit",
                  color: "#fff",
                  cursor: "default",
                  fontSize: "14px",
                  userSelect: "none",
                  whiteSpace: "pre-line",
                }}
              >
                {ICON_MAPPING[text]}
              </span>
            </Tooltip>
          );
        } else {
          // Render other buttons with text and icons
          return renderButton(text, ICON_MAPPING[text]);
        }
      })}
    </>
  );
};

const determineAllowedTabs = (userRole) => {
  let allowedTabs = [];
  if (userRole !== undefined) {
    if (userRole.includes("Role.User")) {
      allowedTabs = allowedTabs.concat(["Chat"]);
    }

    // if (userRole.includes("Role.DS")) {
    //   allowedTabs = allowedTabs.concat(["Prompt Exploration"]);
    // }

    if (userRole.includes("Role.Admin")) {
      allowedTabs = allowedTabs.concat(["Status", "Search", "Compare"]);
    }
  }

  allowedTabs = allowedTabs.concat(["Home", "Help", "Logout"]);

  // Remove duplicate tabs (if any)
  return [...new Set(allowedTabs)];
};

export default function Navbar({ email, userName, userRole, logout }) {
  const navigate = useNavigate();
  const navigateTo = (page) => {
    navigate(`/${page.toLowerCase().replace(/\s/g, "-")}`);
  };
  const allowedTabs = determineAllowedTabs(userRole);

  const PrivateRoute = ({ element, roles }) => {
    if (roles.every((item) => userRole.includes(item))) {
      return element;
    } else {
      return <Error403 />;
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar>
        <Toolbar
          style={{
            marginLeft: 0,
            paddingLeft: 0,
            height: 60,
          }}
        >
          <div
            style={{
              height: "100%",
            }}
          >
            <RowSpanWithSpaceBetween fullHeight>
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    margin: 16,
                  }}
                  src={logo}
                />
              </div>
              <Typography
                variant="h6"
                style={{
                  margin: "auto",
                }}
              >
                Intelligent Knowledge Management
              </Typography>
            </RowSpanWithSpaceBetween>
          </div>
          <Box component="main" sx={{ display: "flex", marginLeft: "auto" }}>
            <NavigationButtons
              allowedTabs={allowedTabs}
              navigateTo={navigateTo}
              logout={logout}
              userRoles={[userRole]}
              email={email}
              userName={userName}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1 }}
        style={{
          height: "88.5%",
          // backgroundColor: "red",
        }}
      >
        <DrawerHeader />
        <Routes>
          <Route
            path="/"
            element={
              <Home userName={userName} userRole={userRole} roles={[]} />
            }
          />
          <Route
            path="/login"
            element={
              <Home userName={userName} userRole={userRole} roles={[]} />
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute
                element={<Home userName={userName} userRole={userRole} />}
                roles={[]}
              />
            }
          />
          <Route
            path="/compare"
            element={
              <PrivateRoute
                element={<Compare userName={userName} userRole={userRole} />}
                roles={["Role.User", "Role.Admin"]}
              />
            }
          />
          <Route
            path="/search"
            element={
              <PrivateRoute
                element={<Search userRole={userRole} />}
                roles={["Role.User", "Role.Admin"]}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute
                element={<Chat userName={userName} userRole={userRole} />}
                roles={["Role.User"]}
              />
            }
          />
          <Route
            path="/status"
            element={
              <PrivateRoute
                element={<StatusPage userName={userName} userRole={userRole} />}
                roles={["Role.Admin"]}
              />
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}
