import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import { Card, Stack } from "@mui/material";

// Local import
import "../index.css";

import {
  ChatContentContainer,
  InputContainer,
  InputField,
  FeatureButtons,
} from "./style";
import LoadingSpan from "../../common/LoadingSpan";
import { MessageBubble } from "./MessageBubble";
import { feedBackMessage } from "../../api";
import { NO_SELECTED_SESSION_VALUE } from "./ChatSideBar";
import RowSpanWithSpaceBetween from "../../common/RowSpanWithSpaceBetween";
import { ChatMessageFilterSection } from "./ChatFilterSection";
import { BOT_GREETING_MESSAGE } from "../../settings/ChatMessageConst";
import Grid from "@mui/system/Unstable_Grid";
import { PromotionQuestionCard } from "./PromotionQuestionCard";

export const ChatMessageView = ({
  sessionId,
  messages,
  isInitiating = false,
  isGenerating = false,
  onAsyncInputSubmit = async () => { },
}) => {
  // pre-computer state
  const isChatStart = !!messages && messages.length > 0;
  ;
  const messageContainerRef = useRef(null);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Scroll to the bottom of the container
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [messages]);

  // Event handler
  const handleSubmitMessage = async (e) => {
    setLoading(true);
    await onAsyncInputSubmit(input);
    setInput("");
    setLoading(false);
  };

  const handleFeedback = async ({ feedback, messageId }) => {
    if (sessionId === NO_SELECTED_SESSION_VALUE) return;
    await feedBackMessage({
      sessionId,
      messageId,
      feedback,
    });
  };


  if (isInitiating) {
    return (
      <ChatContentContainer>
        <LoadingSpan />
      </ChatContentContainer>
    );
  }

  if (!isChatStart) {
    messages = [
      {
        id: 1,
        role: "bot",
        content: BOT_GREETING_MESSAGE,
      },
    ];
  }

  return (
    <ChatContentContainer>
      <div
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <RowSpanWithSpaceBetween>
          <h3>Chat History</h3>
          <ChatMessageFilterSection />
        </RowSpanWithSpaceBetween>

        {/* Message Stack */}

        <Stack
          direction="column"
          style={{
            // maxWidth: "90vw",
            maxHeight: "80%",
            minHeight: "80%",
            overflowY: "scroll",
            width: "100%",
            backgroundColor: "white",
            border: "1px dashed #ccc",
          }}
        >
          {messages.map((msg, index) => (
            <MessageBubble
              scrollRef={
                index === messages.length - 1 ? messageContainerRef : null
              }
              key={index}
              isUser={msg.role === "human"}
              content={msg.content}
              imageUrls={msg.imageUrls}
              isShowFeedBack={isChatStart}
              isShowCopyBtn={isChatStart}
              feedback={msg.role === "human" ? "" : msg.feedback}
              onFeedback={({ feedback }) =>
                handleFeedback({
                  messageId: msg.messageId,
                  feedback: feedback,
                })
              }
            />
          ))}
          {/* Loading Bubble */}
          {isGenerating ? (
            <MessageBubble
              key={-1}
              isUser={0}
              isContent={false}
              isShowFeedBack={false}
              isShowCopyBtn={false}
            >
              <LoadingSpan />
            </MessageBubble>
          ) : (
            <></>
          )}

          {/* Promotion Field */}
          {!isChatStart ? (
            <div
              style={{
                position: "relative",
                top: "20vh",
                left: "2vw",
                maxWidth: "70vw",
              }}
            >
              <Grid container spacing={4}>
                <Grid xs={6}>
                  <PromotionQuestionCard
                    headerStr={"You want to know about P-Series?"}
                    subTextStr={
                      "What guidelines apply to Petrobras offshore production installations for P84 compliance?"
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <PromotionQuestionCard
                    headerStr={
                      "You want to know about P-Series Lessons Learned?"
                    }
                    subTextStr={
                      "What are Key lessons learned in Topside weight control for P78 Project?"
                    }
                  />
                </Grid>

                <Grid xs={6}>
                  <PromotionQuestionCard
                    headerStr={
                      "You want to know about Offshore Substation Project?"
                    }
                    subTextStr={
                      "What are the means to detect fire onboard the OSS?"
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <PromotionQuestionCard
                    headerStr={
                      "You want to know about Offshore Substation Project?"
                    }
                    subTextStr={
                      "What is the earthing standards of Ducting installation?"
                    }
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </Stack>

        {/* Input Container */}
        <InputContainer>
          <InputField
            label="Type a message"
            variant="outlined"
            multiline
            placeholder="Send message"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            disabled={loading}
          />
          <Tooltip title="Send Message" arrow>
            <FeatureButtons
              variant="contained"
              color="primary"
              onClick={handleSubmitMessage}
              disabled={loading}
            >
              <SendIcon />
            </FeatureButtons>
          </Tooltip>
        </InputContainer>
      </div>
    </ChatContentContainer>
  );
};
