import React, { useState, useEffect, useContext } from "react";

// Local import
import "../index.css";

import { ChatContainer } from "./style";
import { ChatMessageView } from "./ChatMessageView";
import { ChatSideBar, NO_SELECTED_SESSION_VALUE } from "./ChatSideBar";
import {
  fetchMessage,
  fetchAllChatSession,
  generateSessionId,
} from "../../api";

import {
  authSocket,
  untilSocketAvailable,
  createChatSocket,
  submitMessage,
} from "../../api/socketHelper";
import { getCurrentEpochTime } from "../../common/EpochTime";
import { ChatFilterContext } from "./ChatFilterContext";
import { Snackbar, Alert } from "@mui/material";
import { getFilterDefaultState } from "../../settings/SearchFilterConst";

const ChatLogic = () => {
  // State
  const [selectedSession, setSelectedSession] = useState(
    NO_SELECTED_SESSION_VALUE
  );
  const [allowReloadSession, setAllowReloadSession] = useState(true);
  const [isLoadingChatSession, setIsLoadingChatSession] = useState(false);
  const [chatSessions, setChatSessions] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatSocket, setChatSocket] = useState(null);
  const [socketSessionId, setSocketSessionId] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const { filterValue, updateFilterValue } = useContext(ChatFilterContext);

  const setupWebsocket = (sessionId, isNew) => {
    if (!isNew && chatSocket !== null)
      return { socket: chatSocket, existed: true };
    if (isNew && chatSocket !== null) {
      // Teardown
      try {
        chatSocket.close();
      } catch (error) {
        console.error(error);
      }
    }

    // Build socket
    let socket = createChatSocket({ chatSessionId: sessionId });
    setChatSocket(socket);
    return { socket: socket, existed: false };
  };

  // Load chat session
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingChatSession(true);
      const chatSessionArray = await fetchAllChatSession();

      const chatSessionOptions = chatSessionArray.reduce(
        (accumulator, curSession, index) => {
          curSession.name =
            curSession.name.length > 0 ? curSession.name : "Session Name";
          accumulator[curSession.sessionId] = curSession;
          return accumulator;
        },
        {}
      );
      setChatSessions(chatSessionOptions);
      setIsLoadingChatSession(false);
    };

    fetchData().catch((error) => {
      console.error(error);
    });

    return () => {
      // Clean up resource:
      if (chatSocket !== null) {
        try {
          chatSocket.close();
        } catch (error) {
          console.error(error);
        }
        setChatSocket(null);
      }
    };
  }, []);

  // On choose session, we fetch history of that
  useEffect(() => {
    // Fetch history of selected session
    if (!allowReloadSession) return;
    if (selectedSession === NO_SELECTED_SESSION_VALUE) {
      return;
    }
    const fetchHistory = async () => {
      setIsLoadingMessages(true);
      const data = await fetchMessage(selectedSession);
      setMessages(data);
      setIsLoadingMessages(false);
    };

    fetchHistory().catch(console.error);
  }, [selectedSession]);

  // Handlers

  const handleChangeSessionName = ({ sessionId, sessionName }) => {
    chatSessions[sessionId] = {
      ...chatSessions[sessionId],
      name: sessionName,
    };
    setChatSessions({ ...chatSessions });
  };

  const handleSelectSession = ({ sessionId }) => {
    if (!(sessionId in chatSessions)) {
      chatSessions[sessionId] = {
        sessionId,
        name: "New Session",
        updatedAt: getCurrentEpochTime(),
        createdAt: getCurrentEpochTime(),
      };
      setChatSessions({
        ...chatSessions,
      });
    }

    setSelectedSession(sessionId);
    updateFilterValue({
      ...getFilterDefaultState(),
    });
  };

  const handleDeleteSession = ({ sessionId }) => {
    delete chatSessions[sessionId];
    setChatSessions({ ...chatSessions });
  };

  // On new session, we allow chose category. On first
  // message, we start a new session
  const handleCreateSession = (e) => {
    setMessages([]);
    // handleSelectSession({ sessionId: NO_SELECTED_SESSION_VALUE });
    setSelectedSession(NO_SELECTED_SESSION_VALUE);
  };

  // handle submit chat data
  const handleInputSubmit = async (input) => {
    let sessionId = selectedSession;
    setAllowReloadSession(false);
    if (selectedSession === NO_SELECTED_SESSION_VALUE) {
      // new session case
      sessionId = await generateSessionId();
      // setSelectedSession(sessionId);
      handleSelectSession({ sessionId });
    }
    // Websocket handler
    let isReloadSocket = false;
    if (sessionId !== socketSessionId) {
      isReloadSocket = true;
      setSocketSessionId(sessionId);
    }
    // console.error("🚀 ~ handleInputSubmit ~ isReloadSocket:", isReloadSocket);
    const socketPack = setupWebsocket(sessionId, isReloadSocket);
    const socket = socketPack.socket;
    await untilSocketAvailable(socket);

    if (!socketPack.existed) {
      await authSocket(socket);
    }
    // Setup message receiver
    let _messages = [
      ...messages,
      {
        content: input,
        role: "human",
        // NOTE: fake messageId for human message, used for responsive
        // server will generate another unique messageId
        messageId: Date.now().toString(),
      },
    ];
    setMessages(_messages);
    setIsGenerating(true);
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const autoFilter = data.autofilter;
      if (autoFilter != null) {
        const projectName = autoFilter.filter.ProjectName;
        // console.log(
        //   "🚀 ~ handleInputSubmit ~ projectName:",
        //   projectName,
        //   autoFilter
        // );
        setSnackbarContent(
          `We detect some keywords and automatically turned on the filter for Project Name: ${projectName}.
          You can turn it off using the filter button.`
        );
        setIsSnackbarOpen(true);
        updateFilterValue({
          ...getFilterDefaultState(),
          ProjectName: projectName,
        });
      }

      _messages = [
        ..._messages,
        {
          content: data.content,
          role: "bot",
          messageId: data.messageId,
          imageUrls: data.imageUrls,
          citations: data.sources,
        },
      ];
      setMessages([..._messages]);
      setIsGenerating(false);
    };

    // Submit data.
    submitMessage({
      ws: socket,
      content: input,
      filterValue: filterValue,
      documentUrl: filterValue.DocumentURL,
    });

    // Force the sidebar to update the order
    chatSessions[sessionId].updatedAt = getCurrentEpochTime();
    setChatSessions({ ...chatSessions });

    // End of function
    setAllowReloadSession(true);
  };

  // Snackbar for autofilter
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  return (
    <ChatContainer>
      <ChatSideBar
        // Session
        isLoading={isLoadingChatSession}
        chatSessions={chatSessions}
        selectedSession={selectedSession}
        onCreateSession={handleCreateSession}
        onSelectSession={handleSelectSession}
        onChangeSessionName={handleChangeSessionName}
        onDeleteSession={handleDeleteSession}
      />
      <ChatMessageView
        sessionId={selectedSession}
        onAsyncInputSubmit={handleInputSubmit}
        messages={messages}
        isGenerating={isGenerating}
        isInitiating={isLoadingMessages}
      />

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        {/* {snackbarContent} */}
        <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
          {snackbarContent}
        </Alert>
      </Snackbar>
    </ChatContainer>
  );
};

export default ChatLogic;
