export const FILTER_OPTIONS = {
  // key: [option1, option2]; otherwise it's input field
  DocumentType: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    { value: "Technical Specifications", content: "Technical Specifications" },
    { value: "Drawing", content: "Drawing" },
    { value: "Data sheet", content: "Data sheet" },
    { value: "Rules", content: "Rules" },
    { value: "Vendor Specifications", content: "Vendor Specifications" },
    { value: "Technical Report", content: "Technical Report" },
    { value: "Others.", content: "Others." },
    { value: "Lessons Learned", content: "Lessons Learned" },
    { value: "TQFs", content: "TQFs" },
    { value: "NA", content: "NA" },
  ],

  ProjectName: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    {
      value: "Offshore Substation - B386",
      content: "Offshore Substation - B386",
    },
    {
      value: "Petrobras P84",
      content: "Petrobras P84",
    },
    {
      value: "Petrobras P78",
      content: "Petrobras P78",
    },
    {
      value: "Petrobras P80",
      content: "Petrobras P80",
    },
    {
      value: "Petrobras P82",
      content: "Petrobras P82",
    },
  ],

  Discipline: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    { value: "ARCHITECTURE", content: "ARCHITECTURE" },
    { value: "AUTOMATION", content: "AUTOMATION" },
    { value: "ELEECTRICAL", content: "ELEECTRICAL" },
    { value: "HVAC", content: "HVAC" },
    { value: "MARINE", content: "MARINE" },
    { value: "PIPING", content: "PIPING" },
    { value: "SAFETY", content: "SAFETY" },
    { value: "TELECOM", content: "TELECOM" },
    { value: "STRUCTURE", content: "STRUCTURE" },
    { value: "NA", content: "NA" },
    { value: "COMMISSIONING", content: "COMMISSIONING" },
    { value: "Choice 14", content: "Choice 14" },
    { value: "DYNAMIC", content: "DYNAMIC" },
    {
      value: "STATATIONARY",
      content: "STATATIONARY",
    },
  ],
  ProjectPhase: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    { value: "Conceptual", content: "Conceptual" },
    { value: "FEED", content: "FEED" },
    { value: "Details stage", content: "Details stage" },
    { value: "As built", content: "As built" },
    { value: "NA", content: "NA" },
  ],
  Ownership: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    { value: "Internal", content: "Internal" },
    { value: "External", content: "External" },
    { value: "Regulatory", content: "Regulatory" },
    { value: "NA", content: "NA" },
  ],
  VersionControl: [
    { value: "", content: "No Filter" },
    { value: "default", content: "default" },
    { value: "Preliminary", content: "Preliminary" },
    { value: "Revised", content: "Revised" },
    { value: "Approved", content: "Approved" },
    { value: "NA", content: "NA" },
  ],
};

//
export const DEFAULT_DOCUMENT_URL = "";
export const DEFAULT_DISCIPLINE_VALUE = "";
export const DEFAULT_PROJECT_PHASE_VALUE = "";
export const DEFAULT_OWNERSHIP_VALUE = "";
export const DEFAULT_VERSION_CONTROL_VALUE = "";
export const DEFAULT_DOCUMENT_TYPE = "";
export const DEFAULT_PROJECT_NAME = "";
export const DEFAULT_TAGS = "";

export const getFilterDefaultState = () => {
  return {
    DocumentURL: DEFAULT_DOCUMENT_URL,
    DocumentType: DEFAULT_DOCUMENT_TYPE,
    ProjectName: DEFAULT_PROJECT_NAME,
    Tags: DEFAULT_TAGS,
    Discipline: DEFAULT_DISCIPLINE_VALUE,
    ProjectPhase: DEFAULT_PROJECT_PHASE_VALUE,
    Ownership: DEFAULT_OWNERSHIP_VALUE,
    VersionControl: DEFAULT_VERSION_CONTROL_VALUE,
  };
};
