import React, { useState } from "react";
import { RoundaboutLeft, CopyAll, CopyAllOutlined } from "@mui/icons-material";
import {
  Card,
  CardActions,
  IconButton,
  Typography,
  Snackbar,
} from "@mui/material";

export const PromotionQuestionCard = ({ headerStr, subTextStr }) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const handleClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(subTextStr).catch(console.error);
    setIsSnackBarOpen(true);
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="h5" component="div">
        {headerStr}
      </Typography>
      <Typography sx={{ mb: 1.5, minHeight: "2.9rem" }} color="text.secondary">
        {subTextStr}
      </Typography>
      <CardActions disableSpacing>
        <IconButton onClick={handleCopy} sx={{ marginLeft: "auto" }}>
          <CopyAllOutlined color="primary" />
        </IconButton>
      </CardActions>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={2000}
        message="Text Copied!"
        onClose={() => {
          setIsSnackBarOpen(false);
        }}
      />
    </Card>
  );
};
