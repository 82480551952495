import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { MenuItem, Stack } from "@mui/material";

// Local import
import "../index.css";
import { OptionContainer } from "./searchStyle";
import {
  FILTER_OPTIONS,
  getFilterDefaultState,
} from "../../settings/SearchFilterConst";

export function SearchSideBar({ onChangeFilter = (newFilterValue) => {} }) {
  const [currentFilter, setCurrentFilter] = useState({
    ...getFilterDefaultState(),
  });

  const handleFilterChange = (key, value) => {
    const newFilter = { ...currentFilter, [key]: value };
    setCurrentFilter(newFilter);
    onChangeFilter(newFilter);
  };

  return (
    <OptionContainer>
      <Stack
        direction={"column"}
        spacing={4}
        style={{
          margin: "auto",
          width: "95%",
        }}
      >
        {/* Document Type Filter */}

        <TextField
          select
          label="Filter: Document Type"
          value={currentFilter.DocumentType}
          onChange={(e) => handleFilterChange("DocumentType", e.target.value)}
          fullWidth
        >
          {FILTER_OPTIONS.DocumentType.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.content}
            </MenuItem>
          ))}
        </TextField>

        {/* Project Name Filter */}
        <TextField
          select
          label="Filter: Project name"
          value={currentFilter.ProjectName}
          onChange={(e) => handleFilterChange("ProjectName", e.target.value)}
          fullWidth
        >
          {FILTER_OPTIONS.ProjectName.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.content}
            </MenuItem>
          ))}
        </TextField>

        {/* Discipline Filter */}
        <TextField
          select
          label="Filter: Discipline"
          value={currentFilter.Discipline}
          onChange={(e) => handleFilterChange("Discipline", e.target.value)}
          fullWidth
          // style={{ marginLeft: "16px", marginTop: "58px", width: "100%" }}
        >
          {FILTER_OPTIONS.Discipline.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.content}
            </MenuItem>
          ))}
        </TextField>

        {/* Project Phase Filter */}
        <TextField
          select
          label="Filter: Project Phase"
          value={currentFilter.ProjectPhase}
          onChange={(e) => handleFilterChange("ProjectPhase", e.target.value)}
          fullWidth
        >
          {FILTER_OPTIONS.ProjectPhase.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.content}
            </MenuItem>
          ))}
        </TextField>

        {/* Ownership Filter */}
        <TextField
          select
          label="Filter: Ownership"
          value={currentFilter.Ownership}
          onChange={(e) => handleFilterChange("Ownership", e.target.value)}
          fullWidth
        >
          {FILTER_OPTIONS.Ownership.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>

        {/* Version Control Filter */}
        <TextField
          select
          label="Filter: Version Control"
          value={currentFilter.VersionControl}
          onChange={(e) => handleFilterChange("VersionControl", e.target.value)}
          fullWidth
        >
          {FILTER_OPTIONS.VersionControl.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.content}
            </MenuItem>
          ))}
        </TextField>

        {/* Tags Filter */}
        <TextField
          label="Filter: Tags"
          value={currentFilter.Tags}
          onChange={(e) => handleFilterChange("Tags", e.target.value)}
          fullWidth
        ></TextField>
      </Stack>
    </OptionContainer>
  );
}
