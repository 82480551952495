import { useMemo, useState, useContext } from "react";

// Local import
import { Button } from "@mui/material";
import { ChatFilterDialog } from "./ChatFilterDialog";
import { ChatFilterContext } from "../ChatFilterContext";
export function ChatMessageFilterSection() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { filterValue, _ } = useContext(ChatFilterContext);

  // const handleSaveFilter = ({ ...data }) => {
  //   setCurrentFilter({ ...data });
  //   onChangeFilter({ ...data });
  // };

  const numberActiveFilter = useMemo(() => {
    const count = Object.entries(filterValue).filter(
      ([k, v]) => v.length > 0
    ).length;

    return count;
  }, [filterValue]);

  return (
    <>
      <Button
        color={numberActiveFilter === 0 ? "primary" : "error"}
        onClick={() => setIsDialogOpen(true)}
      >
        Open Filter ({numberActiveFilter})
      </Button>
      {/* Conditional render to force this componenet to get the latest context value */}
      {isDialogOpen ? (
        <ChatFilterDialog
          isOpen={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
}
