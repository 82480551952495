import Session from "react-session-api";

export function authHeader() {
  let data, jwt;

  data = Session.get("accountInfo");
  jwt = JSON.parse(data).jwtIdToken;

  // if (jwt != null) {
  return {
    Authorization: `Bearer ${jwt}`,
  };
  // }

  // const id = setInterval(() => {
  //   count += 1;
  //   if (count > 3) {
  //     throw new Error("Can't read the Session for JWT after 3 attempts");
  //   }
  //   data = Session.get("accountInfo");
  //   jwt = JSON.parse(data).jwtIdToken;
  //   if (jwt != null) {
  //     clearInterval(id);
  //     return {
  //       Authorization: `Bearer ${jwt}`,
  //     };
  //   }
  // }, 3000);
}
