import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export default function RenameSessionDialog({
  isOpen,
  currentSessionName = "",
  handleClose = () => {},
  onSubmitRename = ({ sessionName }) => {},
}) {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const sessionName = formJson.sessionName;
            onSubmitRename({ sessionName });
            handleClose();
          },
        }}
      >
        <DialogTitle>Session Rename</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To rename this chat session, enter your desired session name.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="sessionName"
            name="sessionName"
            label="Session Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={currentSessionName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
