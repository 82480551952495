import { styled } from "@mui/system";

export const SearchContainer = styled("div")({
  display: "flex",
  height: "90vh",

  // marginTop: "-20px",
  // marginLeft: "-14px",
  // marginBottom: "-100px",
  overflow: "hidden", // Prevent scroll bar
  overflowY: "auto",
});

export const OptionContainer = styled("div")({
  flex: 1, // Adjusted the flex property
  overflowY: "auto",
  padding: "16px 4px",
  borderRight: "1px solid #ccc",
  backgroundColor: "#f5f5f5",
});

export const MainContentContainer = styled("div")({
  flex: 4, // Adjusted the flex property
  overflowY: "auto",
  marginTop: "8px",
  marginLeft: "8px",
  padding: "4px",
  width: "100%",
});
