import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

// Local import
import "../index.css";
import AlertModal from "../ModalPopupAlert";
import { SearchContainer, MainContentContainer } from "./compareStyle";
import { MarkdownTextField } from "../../common/Markdown";

import { parseDiff, Diff, Hunk } from "react-diff-view";
import "react-diff-view/style/index.css";
// import { EXAMPLE_DATA } from "./test.data";
import { Stack } from "@mui/material";
import RowSpanWithSpaceBetween from "../../common/RowSpanWithSpaceBetween";
import { fetchPageDiff, submitValidateUrl } from "../../api";
import LoadingSpan from "../../common/LoadingSpan";

function renderFile({ idx, oldRevision, newRevision, type, hunks }) {
  return (
    <Diff
      key={oldRevision + "-" + newRevision + idx}
      viewType="split"
      diffType={type}
      hunks={hunks}
    >
      {(hunks) => hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)}
    </Diff>
  );
}
const Compare = () => {
  const [loading, setLoading] = useState(false);
  const [sourceDocUrl, setSourceDocUrl] = useState("");
  const [targetDocUrl, setTargetDocUrl] = useState("");
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [diffData, setDiffData] = useState(null);
  const [overviews, setOverviews] = useState([]);
  const [sourceDocPageStart, setSourceDocPageStart] = useState(1);
  const [sourceDocPageEnd, setSourceDocPageEnd] = useState(-1);
  const [targetDocPageStart, setTargetDocPageStart] = useState(1);
  const [targetDocPageEnd, setTargetDocPageEnd] = useState(-1);

  // Handler
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent the default Enter key behavior (e.g., adding a new line)
      e.preventDefault();
      handleCompare();
    }
  };

  const parsePageDiff = (responseItem) => {
    let diffText = responseItem.diff;
    diffText = diffText.replace("\n\n", "\n");
    diffText = diffText.split("\n").slice(2).join("\n");
    const magicStr = `diff --git a/source_doc b/target_doc\nindex ignore..ignore ignore\n--- a/source_doc\n+++ b/target_doc\n`;
    diffText = magicStr + diffText;
    const parsedDiff = parseDiff(diffText, {
      nearbySequences: "zip",
    });
    return parsedDiff;
  };

  const handleCompare = async () => {
    let mockSourceDocUrl, mockTargetDocUrl;
    try {
      const { url, ...value } = await submitValidateUrl({
        url: sourceDocUrl,
      });
      mockSourceDocUrl = url;
      setSourceDocUrl(mockSourceDocUrl);
    } catch (error) {
      console.log("🚀 ~ handleCompare ~ error:", error);
      return;
    }

    try {
      const { url, ...value } = await submitValidateUrl({
        url: targetDocUrl,
      });
      mockTargetDocUrl = url;
      setTargetDocUrl(url);
    } catch (error) {
      console.log("🚀 ~ handleCompare ~ error:", error);
    }

    setLoading(true);
    const data = await fetchPageDiff({
      sourceDocUrl: mockSourceDocUrl,
      targetDocUrl: mockTargetDocUrl,
      sourceStartPage: sourceDocPageStart,
      sourceEndPage: sourceDocPageEnd,
      targetStartPage: targetDocPageStart,
      targetEndPage: targetDocPageEnd,
    });
    // const data = EXAMPLE_DATA;
    const diffs = data.pageDiffs
      .map((diffResponse) => parsePageDiff(diffResponse))
      .flat();
    setDiffData(diffs);
    setOverviews(data.overviews);
    setLoading(false);
  };

  return (
    <SearchContainer>
      {/* <SearchSideBar onChangeFilter={handleOnChangeFilter} /> */}
      <MainContentContainer>
        <Container maxWidth="100vw">
          <Stack gap={2}>
            <Typography variant="h5">Compare Documents</Typography>

            <RowSpanWithSpaceBetween>
              <TextField
                id="sourceDocUrl"
                label="Source Document"
                variant="outlined"
                fullWidth
                value={sourceDocUrl}
                onChange={(e) => setSourceDocUrl(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <TextField
                id="sourceDocPageStart"
                label="Start Page"
                variant="outlined"
                type="number"
                value={sourceDocPageStart}
                onChange={(e) => setSourceDocPageStart(Number(e.target.value))}
                onKeyDown={(e) => handleKeyDown(e)}
                sx={{ width: "150px" }}
              />
              <TextField
                id="sourceDocPageEnd"
                label="End Page"
                variant="outlined"
                type="number"
                value={sourceDocPageEnd}
                onChange={(e) => setSourceDocPageEnd(Number(e.target.value))}
                onKeyDown={(e) => handleKeyDown(e)}
                sx={{ width: "150px" }}
              />
            </RowSpanWithSpaceBetween>

            <RowSpanWithSpaceBetween>
              <TextField
                id="targetDocUrl"
                label="Target Document"
                variant="outlined"
                fullWidth
                value={targetDocUrl}
                onChange={(e) => setTargetDocUrl(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <TextField
                id="targetDocPageStart"
                label="Start Page"
                variant="outlined"
                type="number"
                value={targetDocPageStart}
                onChange={(e) => setTargetDocPageStart(Number(e.target.value))}
                onKeyDown={(e) => handleKeyDown(e)}
                sx={{ width: "150px" }}
              />
              <TextField
                id="targetDocPageEnd"
                label="End Page"
                variant="outlined"
                type="number"
                value={targetDocPageEnd}
                onChange={(e) => setTargetDocPageEnd(Number(e.target.value))}
                onKeyDown={(e) => handleKeyDown(e)}
                sx={{ width: "150px" }}
              />
            </RowSpanWithSpaceBetween>

            <Button variant="contained" onClick={handleCompare}>
              Run Compare Documents
            </Button>
          </Stack>
        </Container>
        {loading ? <LoadingSpan /> : <></>}
        <OverviewScope overviews={overviews} />
        {diffData ? (
          <Container>
            <h1>Detail comparison</h1>
            <div>
              {diffData.map((value, idx) => renderFile({ idx, ...value }))}
            </div>
          </Container>
        ) : (
          <></>
        )}
      </MainContentContainer>
      <AlertModal
        open={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        message={messageDisplay}
      />
    </SearchContainer>
  );
};

export default Compare;

function OverviewScope({ overviews }) {
  if (!overviews) return <></>;
  if (overviews.length === 0) return <></>;
  return (
    <Container>
      <h1>Summarization of Change Per Page</h1>
      <div
        style={{
          minHeight: "fit-content",
        }}
      >
        {overviews.map((overview, index) => (
          <MarkdownTextField key={index} value={overview.content} />
        ))}
      </div>
    </Container>
  );
}
