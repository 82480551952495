import { useState } from "react";
import { Modal, Box } from "@mui/material";

const centerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const ImageModel = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <img
        src={imageUrl}
        alt="Retrieved Image"
        style={{ height: "40px", cursor: "pointer" }}
        onClick={() => setOpen(true)}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={centerStyle}>
          <img
            key={imageUrl}
            src={imageUrl}
            alt="Retrieved Image"
            style={{
              width: "50vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
